<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-29 13:17:48
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-04-01 14:54:51
 * @Description  : 修改组织备注
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
   <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("modify_remarks") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="addForm"
        status-icon
        ref="resetRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label-width="0px">
          <el-input
            clearable
            v-model="addForm.remark"
            autocomplete="off"
            :placeholder="$t('please_enter')"
            type="textarea"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { modifyNoteApi } from "@/api/api.js";

export default {
  name: "ChangeOrganNoteDialog",
  data() {
    return {
      addForm: {
        remark: "",
      },
      dialogFormVisible: false,
      orgId: "",
    };
  },
  methods: {
    showDialog(id) {
      this.dialogFormVisible = true;
      this.orgId = id;
    },
    handelOk() {
      let params = {
        ...this.addForm,
        orgId: this.orgId,
        name: "",
        owner: "",
      };
      modifyNoteApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("modify_success"));
        this.dialogFormVisible = false;
        this.$emit("refresh");
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less"></style>
